import React from 'react'
import PageTemplate from '../components/pageTemplate'
import Helmet from "react-helmet";

let Office = () => (
  <PageTemplate title="Microsoft Office 365">
    <div id='dccn-emdedITdiscoveroffice'></div>
    <Helmet>
      <script>{`var ccs_cc_args = ccs_cc_args || [];
                ccs_cc_args.push(['bannerHeight', '250']); 
                ccs_cc_args.push(['bannerWidth', '300']); 
                ccs_cc_args.push(['embed', 'inline']); 

                (function () {
                  var o = ccs_cc_args; o.push(['_SKey', 'aca49781']); o.push(['_ZoneId', 'emdedITdiscoveroffice']);
                  var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true;
                  sc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.cnetcontent.com/jsc/h.js';
                  var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(sc, n);
                })();
`}</script>
    </Helmet>
  </PageTemplate>
)

export default Office
